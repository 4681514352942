/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Checkbox, message } from 'antd';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import { EducatorButton, EducatorInputField, HelperBot } from '@components/commonComponents';
import { Navbar, Multistep } from '@components/layoutComponents';
import { GOOGLE_SSO_AUTH_URL, clearCognitoStorage, USER_ROLE, getLocalStorageItem } from '@utils';

import googleImage from '@assets/images/googleImage.png';

import './SignUp.scss';

export const SignUp = () => {
  const { t: teacherCreateT, i18n } = useTranslation('translation', { keyPrefix: 'teacher_create_account' });
  const { t: commonT } = useTranslation('translation', { keyPrefix: 'common' });
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState({ fname: '', lname: '', email: '', password: '' });
  const [refer, setRefer] = useState('');
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [receivePromotionMaterial, setReceivePromotionMaterial] = useState(false);
  const [googleSpinner, setGoogleSpinner] = useState(false);

  const isHomeschoolTeacherFlow = getLocalStorageItem('isHomeschoolTeacher');

  const handlerSubmit = () => {
    clearCognitoStorage();

    if (isCheckBox) {
      setButtonSpinner(true);
      const username = data.email.replace('@', '_');
      if (refer !== '') {
        Auth.signUp({
          username: username,
          password: data.password,
          attributes: {
            preferred_username: username,
            email: data.email,
            given_name: data.fname,
            family_name: data.lname,
            'custom:referral_identifier': refer,
            'custom:preferred_language': i18n.language,
            'custom:user_role': `${USER_ROLE.TEACHER}`
          }
        })
          .then(() => {
            setButtonSpinner(false);
            navigate('/teacher-create-account/email-confirmation', {
              state: { ...data, username, receivePromotionMaterial }
            });
          })
          .catch(err => {
            message.error(`${err.message}`);
            setButtonSpinner(false);
          });
      } else {
        Auth.signUp({
          username: username,
          password: data.password,
          attributes: {
            preferred_username: username,
            email: data.email,
            given_name: data.fname,
            family_name: data.lname,
            'custom:user_role': `${USER_ROLE.TEACHER}`,
            'custom:preferred_language': i18n.language
          }
        })
          .then(() => {
            setButtonSpinner(false);
            navigate('/teacher-create-account/email-confirmation', {
              state: { ...data, username, receivePromotionMaterial }
            });
          })
          .catch(err => {
            message.error(`${err.message}`);
            setButtonSpinner(false);
          });
      }
    } else {
      setButtonSpinner(false);
      message.error(teacherCreateT('first_select_terms'));
    }
  };

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const googleSignIn = () => {
    clearCognitoStorage();
    setGoogleSpinner(true);
    window.location.href = GOOGLE_SSO_AUTH_URL;
  };

  useEffect(() => {
    const data = location.search.slice(1);
    setRefer(data);
  }, []);

  const onChangedCheck = event => {
    const { checked } = event.target;
    setIsCheckBox(checked);
  };

  const onPromotionCheck = event => {
    const { checked } = event.target;
    setReceivePromotionMaterial(checked);
  };

  return (
    <div className="create-account__container mainDivSignUpStyle  container-fluid">
      <Navbar />
      <div className="row py-5 mt-5">
        <div className="col-3 d-flex justify-content-center">
          <Multistep select="Personal Details" check={[0, 0, 0, 0]} />
        </div>
        <div className="col-6 col-md-5 col-lg-6  d-flex justify-content-center">
          <Form onFinish={handlerSubmit} initialValues={{ check: true }}>
            <div className="textStyleHeading">
              {teacherCreateT(isHomeschoolTeacherFlow ? 'create_homeschool_account' : 'create_teacher_account')}
            </div>
            <div className="aboutTextStyle">{teacherCreateT('let_us_know_about_you')}</div>
            <div className="pt-4 inlineFormDiv">
              <Form.Item name="fname" rules={[{ required: true, message: commonT('input_first_name') }]}>
                <EducatorInputField
                  label={teacherCreateT('first_name')}
                  name="fname"
                  type="text"
                  inputValue={data.fname}
                  small
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <Form.Item name="lname" rules={[{ required: true, message: commonT('input_last_name') }]}>
                <EducatorInputField
                  label={teacherCreateT('last_name')}
                  name="lname"
                  type="text"
                  inputValue={data.lname}
                  small
                  onChange={onChangeHandle}
                />
              </Form.Item>
            </div>
            <Form.Item className="pt-3" name="email" rules={[{ required: true, message: commonT('input_email') }]}>
              <EducatorInputField
                label={teacherCreateT('email')}
                type="email"
                name="email"
                inputValue={data.email}
                onChange={onChangeHandle}
              />
            </Form.Item>
            <Form.Item
              className="pt-3"
              name="password"
              rules={[
                { required: true, message: commonT('input_password') },
                {
                  required: true,
                  pattern: new RegExp(/^(?=.*\d)(?=.*?[@$!%*#?&^_.,-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
                  message: commonT('password_requirements')
                }
              ]}>
              <EducatorInputField
                label={teacherCreateT('password')}
                type="password"
                passwordValue={data.password}
                name="password"
                onChange={onChangeHandle}
              />
            </Form.Item>

            <div className="textStyle400Cabin18">
              <div className="displayInline my-3">
                <Checkbox onChange={onChangedCheck} />
                <div
                  className="t-and-c"
                  dangerouslySetInnerHTML={{
                    __html: teacherCreateT('terms_and_privacy_policy')
                  }}
                />
              </div>
            </div>

            <div className="textStyle400Cabin18">
              <div className="displayInline my-3">
                <Checkbox onChange={onPromotionCheck} />
                <div
                  className="t-and-c"
                  dangerouslySetInnerHTML={{
                    __html: teacherCreateT('marketing_and_promotion')
                  }}
                />
              </div>
            </div>

            <Form.Item>
              <EducatorButton
                label={teacherCreateT('create_account')}
                type="submit"
                showSpinner={buttonSpinner}
                dataTestId="createAccountButton"
              />
            </Form.Item>
            <div className="textStyle400Cabin18">{teacherCreateT('or')}</div>
            <div className="pt-2">
              <EducatorButton
                label={teacherCreateT('sign_up_with_google')}
                className="googleStyle"
                type="button"
                onClick={googleSignIn}
                showSpinner={googleSpinner}
                icon={<img src={googleImage} alt="googleLogo" width={40} height={40} />}
                dataTestId="googleSignUpButton"
              />
            </div>
          </Form>
        </div>
        <div className="col-3 pb-3 d-flex align-items-end">
          <HelperBot>
            <div
              dangerouslySetInnerHTML={{
                __html: teacherCreateT('account_access_and_resources')
              }}
            />
          </HelperBot>
        </div>
      </div>
    </div>
  );
};
export default SignUp;
