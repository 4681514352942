import React from 'react';
import PropTypes from 'prop-types';
import {
  Lock as LockIcon,
  Unlock as UnlockIcon,
  Paperclip as PaperclipIcon,
  Video as VideoIcon,
  VideoOff as VideoOffIcon
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Image } from 'antd';
import { Title, Stack, Text, Colors, Button } from '@teacher-app/design-system';

import { getDataTestId, sendGaEvent } from '@utils';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import UnlockEdubytesModal from '@teacher-app/components/UnlockEdubytesModal';

const StyledCard = styled.div`
  border-radius: 15px;
  overflow: hidden;
  display: block;
  min-width: 100px;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
  padding: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: ${Colors.WHITE_0};
  & .resource-thumbnail:hover {
    .lock-icon {
      display: none;
    }
    .unlock-button {
      display: block;
    }
  }
  & .resource-thumbnail {
    .unlock-button {
      display: none;
    }
  }
`;

const EdubyteCard = React.memo(({ edubyteItem }) => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const { id, name, thumbnail, is_locked, videos } = edubyteItem;
  const [showLockedModal, setShowLockedModal] = React.useState(false);
  const navigate = useNavigate();
  const hasVideo = videos?.length > 0;

  const handleCardClick = () => {
    if (is_locked) return;
    sendGaEvent('card_click', 'edubyte__card_click', name);
    navigate(`/edubytes/${id}`, {
      state: {
        itemId: id,
        title: name
      }
    });
  };

  const handleUnlock = () => {
    if (!is_locked) return;
    setShowLockedModal(true);
  };

  return (
    <>
      <StyledCard onClick={handleCardClick} data-testid={`edubyte-card-${getDataTestId(name)}`}>
        <Stack direction="row" className="position-relative resource-thumbnail">
          <Image width={'100%'} height={150} src={thumbnail} alt="resourcesImage" preview={false} />
          <Stack
            display={is_locked ? 'flex' : 'none'}
            justifyContent="center"
            className="position-absolute h-100"
            style={{ background: 'rgba(38, 38, 41, 0.70)' }}>
            <LockIcon className="lock-icon" color={'#CCC8C7'} size={82} />
            <Button
              size='small'
              data-testid="unlock-edubyte-button"
              className="unlock-button"
              onClick={handleUnlock}>
              {t('unlock_with_licence')}
            </Button>
          </Stack>
        </Stack>
        <Stack direction="column" style={{ padding: '24px 16px' }}>
          <Stack direction="row" spacing={4}>
            {is_locked ? (
              <LockIcon
                size={16}
                style={{ minWidth: '16px', alignSelf: 'flex-start' }}
                strokeWidth={3}
                color={Colors.GREY_50}
              />
            ) : (
              <UnlockIcon
                size={18}
                style={{ minWidth: '18px', alignSelf: 'flex-start' }}
                strokeWidth={3}
                color={Colors.GOLD}
              />
            )}
            <Title data-testid="edubyte-name" ellipsis>{name}</Title>
          </Stack>
          <Stack direction="row" spacing={4}>
            {hasVideo ? (
              <VideoIcon size={18} style={{ minWidth: '18px' }} color={Colors.GREY_50} />
            ) : (
              <VideoOffIcon size={18} style={{ minWidth: '18px' }} color={Colors.GREY_50} />
            )}
            <Text size="small" ellipsis>
              {t('video_lesson')}
            </Text>
          </Stack>
          <Stack direction="row" spacing={4}>
            <PaperclipIcon size={18} style={{ minWidth: '18px' }} color={Colors.GREY_50} />
            <Text size="small" ellipsis>
              {t('teaching_resources')}
            </Text>
          </Stack>
        </Stack>
      </StyledCard>
      <UnlockEdubytesModal isOpen={showLockedModal} onClose={() => setShowLockedModal(false)} />
    </>
  );
});

export default EdubyteCard;

EdubyteCard.propTypes = {
  edubyteItem: PropTypes.object.isRequired,
};
