import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@teacher-app/design-system';
import { message, Modal, Spin } from 'antd';

import { Document, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
import assessmentArt from '@assets/images/assessment_pdf_art.png';
import logo from '@assets/images/new_logo.png';
import { API_URL, getApiWithAuth } from '@utils';
import { useTranslation } from 'react-i18next';

export const styles = StyleSheet.create({
  page: {
    padding: 50
  },
  header: {
    flexDirection: 'row',
    marginBottom: 30,
    width: '100%',
    alignItems: 'center',
    columnGap: 10
  },
  logo: {
    height: 52,
    width: 100
  },
  logoLine: {
    width: 1,
    height: 52,
    backgroundColor: '#CCC8C7'
  },
  textHeader: {
    flexDirection: 'column',
    columnGap: 5,
    height: 52
  },
  classRow: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    marginBottom: 10,
    width: '100%',
    justifyContent: 'space-between'
  },
  className: {
    fontSize: '16px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '100%',
    color: '#222'
  },
  cyberAcademy: {
    fontSize: '24px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '120%',
    color: '#222'
  },
  todayDate: {
    fontSize: '16px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '100%',
    color: '#222'
  },
  assignmentName: {
    marginBottom: 25,
    fontSize: '14px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    color: '#222'
  },
  table: {
    display: 'table',
    width: '100%',
    margin: '0 auto'
  },
  tableRow: {
    flexDirection: 'row',
    backgroundColor: '#fff'
  },
  tableHeaderCell: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#CCC8C7',
    padding: 5
  },
  overallRow: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff'
  },
  overallHeaderCell: {
    padding: 5
  },
  tableHeaderText: {
    fontSize: '16px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '120%',
    color: '#222'
  },
  tableCell: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#CCC8C7',
    padding: 5,
    paddingTop: 10,
    paddingBottom: 10
  },
  studentCell: {
    width: '45%'
  },
  learningGoalCell: {
    width: '60%'
  },
  studentCellText: {
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    color: '#222'
  },
  outcomeCell: {
    width: '40%'
  },
  learningGoalOutcomeCell: {
    width: '25%',
    textAlign: 'center'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  assessmentArt: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 270,
    zIndex: 10
  }
});

export const StudentAccountDetailPdf = ({ isOpen, onClose, currentClass }) => {
  const [loading, setLoading] = useState(true);
  const [loginDetails, setLoginDetails] = useState(null);

  const { t } = useTranslation('translation', { keyPrefix: 'account_details_pdf' });

  useEffect(async () => {
    setLoading(true);
    const { success, data } = await getApiWithAuth(
      `${API_URL.EDIT_CLASS_BY_ID}${currentClass.id}/students/login-details`
    );

    if (success) {
      setLoginDetails(data);
      setLoading(false);
    } else {
      message.error(data.message);
      setLoading(false);
    }
  }, []);

  return (
    <Modal
      width="80vw"
      closable={false}
      centered
      footer={null}
      onCancel={onClose}
      open={isOpen}
      style={{ padding: 0 }}
      bodyStyle={{ padding: 0 }}>
      {loading && (
        <Stack style={{ minHeight: 160 }} justifyContent="center">
          <Spin size="large" />
        </Stack>
      )}
      {!loading && loginDetails && (
        <Stack direction="column" alignItems="flex-start" spacing={12}>
          <PDFViewer style={{ width: '100%', height: '90vh' }}>
            <Document title={`Student Account Details - ${currentClass?.name}`}>
              <Page size="A4" style={styles.page}>
                <Image style={styles.assessmentArt} src={assessmentArt} />
                <View style={styles.header}>
                  <View>
                    <Image style={styles.logo} src={logo} />
                  </View>
                  <Text style={styles.logoLine} />
                  <View>
                    <Text style={styles.cyberAcademy}>{t('heading')}</Text>
                  </View>
                </View>

                <View>
                  <Text style={styles.assignmentName}>{t('heading_text')}</Text>
                </View>
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    <View style={[styles.tableHeaderCell, styles.studentCell]}>
                      <Text style={styles.tableHeaderText}>{t('student_name')}</Text>
                    </View>
                    <View style={[styles.tableHeaderCell, styles.outcomeCell]}>
                      <Text style={styles.tableHeaderText}>{t('username')}</Text>
                    </View>
                    <View style={[styles.tableHeaderCell, styles.outcomeCell]}>
                      <Text style={styles.tableHeaderText}>{t('password')}</Text>
                    </View>
                  </View>
                  {loginDetails.map((loginDetail, i) => (
                    <View key={i} style={styles.tableRow}>
                      <View style={[styles.tableCell, styles.studentCell]}>
                        <Text style={styles.studentCellText}>
                          {loginDetail.first_name} {loginDetail.last_name}{' '}
                        </Text>
                      </View>
                      <View style={[styles.tableCell, styles.outcomeCell]}>
                        <Text style={styles.studentCellText}>{loginDetail.username}</Text>
                      </View>
                      <View style={[styles.tableCell, styles.outcomeCell]}>
                        <Text style={styles.studentCellText}>{loginDetail.password}</Text>
                      </View>
                    </View>
                  ))}
                </View>
                <Text
                  style={styles.pageNumber}
                  render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                  fixed
                />
              </Page>
            </Document>
          </PDFViewer>
        </Stack>
      )}
    </Modal>
  );
};

StudentAccountDetailPdf.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentClass: PropTypes.object.isRequired
};
