import { Form as AntForm } from 'antd';
import React from 'react';
import styled from 'styled-components';
import colors from '../../theme/colors';
import PropTypes from 'prop-types';

const StyledForm = styled(AntForm)`
  width: 100%; 
  &.ant-form-small  {
      .ant-form-item-label>label {
          font-size: 16px;
          line-height: 150%;  
      }
      .ant-input, .ant-picker {
          height: 44px !important;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          height: 44px !important;
      }
  }
  .ant-input-affix-wrapper, .ant-input-password {
      width: 100%;
      color: ${colors.GREY_100};
      font-family: Lexend, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      height: 50px;
      padding-left: 16px;
      padding-right: 13px;
      min-height: 40px;
      border: 2px solid ${colors.LAVENDER_50};
      > input {
          border: none;
          height: 46px;
      }
  }
  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
      font-size: 16px;
      order: 1
  }
    .ant-input-password-icon.anticon {
        > svg {
            color: ${colors.VIOLET}
        }
    }
    .ant-input-password-icon { padding: 0 }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper,
  .ant-checkbox-wrapper {
    margin-right: 10px;
    margin-left: 0;
  }
  
  .ant-picker-status-error,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: ${colors.CHERRY} !important;
  }
  .ant-form-item-explain-error {
    width: 100% !important;
    color: ${colors.CHERRY};
    font-family: Lexend, sans-serif;
    font-size: 16px;
    font-style: normal;
    padding-top: 8px;
    line-height: 150%;
  }
  .ant-form-item {
    width: 100%;
    margin: 0 !important;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    min-height: 26px !important;
  }
  .ant-select-multiple .ant-select-selection-item {
    margin-top: 0;
  }
  .ant-form-item-label > label {
    font-family: Catamaran, sans-serif;
    font-size: 20px;
    font-style: normal;
    white-space: nowrap;
    font-weight: 800;
    line-height: 120%;
    flex-direction: row;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: ${colors.GREY_100};
    font-family: Lexend, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    height: 50px !important;
    width: 100% !important;
    padding: 10px 16px;
    min-height: 40px;
    border: 2px solid ${colors.LAVENDER_50} !important;
    background-color: ${colors.WHITE_0};
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    line-height: inherit !important;
    font-weight: 400 !important;
  }
  .ant-select-disabled .ant-select-selection-placeholder {
    color: ${colors.GREY_100};
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${colors.WHITE_200};
    border-color: ${colors.GREY_0} !important;
  }
  .ant-input,
  .ant-picker {
    width: 100%;
    color: ${colors.GREY_100};
    font-family: Lexend, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    height: 50px;
    padding: 10px 16px;
    min-height: 40px;
    border: 2px solid ${colors.LAVENDER_50};
  }
  .ant-picker-disabled > .ant-picker-input input::placeholder {
    color: ${colors.GREY_100};
  }
  .ant-input-disabled &::placeholder {
    color: ${colors.GREY_100};
  }
  .ant-input[disabled] {
    color: ${colors.GREY_100};
    background-color: ${colors.WHITE_200};
    border-color: ${colors.GREY_0};
  }
  .ant-picker.ant-picker-disabled {
    color: ${colors.GREY_100};
    background-color: ${colors.WHITE_200};
    border-color: ${colors.GREY_0};
  }
  .ant-picker-input > input,
  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    height: 50px;
    color: ${colors.GREY_100};
    font-family: Lexend, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    align-items: center;
    line-height: 150%;
    span.ant-radio + * {
      padding: 0 10px;
    }
  }
  .ant-radio-disabled + span,
  .ant-checkbox-disabled + span {
    color: ${colors.GREY_50};
  }
  .ant-radio-inner {
    width: 30px;
    height: 30px;
    border-color: ${colors.LAVENDER_50};
    :after {
      top: 6px !important;
      left: 6px !important;
      margin-top: -8px !important;
      margin-left: -8px !important;
      width: 32px;
      height: 32px;
      background-color: ${colors.VIOLET};
    }
  }
  .ant-radio-disabled .ant-radio-inner {
    background-color: ${colors.WHITE_200};
    border-color: ${colors.LAVENDER_50};
  }
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${colors.LAVENDER_50};
  }
  .ant-radio,
  .ant-checkbox {
    top: 0;
  }
  .ant-checkbox-inner {
    width: 30px;
    height: 30px;
    border-color: ${colors.LAVENDER_50};
    :after {
      top: 12px;
      left: 8px;
      height: 14px;
      width: 8px;
      border-width: 3px;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colors.VIOLET};
    border-color: ${colors.VIOLET};
  }
  .ant-checkbox-checked.ant-checkbox-disabled {
    .ant-checkbox-inner {
      background-color: ${colors.GREY_0} !important;
      border-color: ${colors.LAVENDER_50} !important;
      :after {
        border-color: ${colors.WHITE_0} !important;
      }
    }
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${colors.WHITE_200};
    border-color: ${colors.LAVENDER_50} !important;
  }
  .ant-checkbox + span {
    padding: 0 10px;
    width: 100%;
  }
  input.ant-input-disabled::-webkit-input-placeholder {
    color: ${colors.GREY_100} !important;
  }
`;

const Form = ({ children, ...rest }) => {
  return <StyledForm {...rest}>{children}</StyledForm>;
};

Form.Item = AntForm.Item;
Form.List = AntForm.List;
Form.ErrorList = AntForm.ErrorList;
Form.useForm = AntForm.useForm;
Form.useFormInstance = AntForm.useFormInstance;
Form.useWatch = AntForm.useWatch;
Form.Provider = AntForm.Provider;

export default Form;

Form.defaultProps = {
  layout: 'vertical'
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  /** Form layout */
  layout: PropTypes.oneOf(['horizontal', 'vertical', 'inline'])
};
