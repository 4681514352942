import React, { useEffect, useState } from 'react';
import { Checkbox, Col, DatePicker, message, Radio, Row, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HelpCircle as HelpCircleIcon } from 'react-feather';
import { AuthenticatedNavbar } from '@components/layoutComponents';
import { useGrades, usePlannerSettings } from '@hooks';
import { useAppState } from '@context';
import { Badge, Button, Card, Colors, Form, Stack, Text, Title } from '../../design-system';

import { API_URL, getApiWithAuth, postApiWithAuth } from '@utils';
import dayjs from 'dayjs';
import moment from 'moment';

import PlannerPreview from './PlannerPreview';
import { getTopic } from '@teacher-app/utils/getTopic';

const { Option } = Select;
export const NewPlanner = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'planner' });
  const { t: teacherT } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const { state } = useAppState();
  const currentClass = state.classes.find(item => item.id === state.classId);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { data: grades } = useGrades();
  const [grade, setGrade] = useState(currentClass.grade);

  const [plannerLoading, setPlannerLoading] = useState(true);
  const { data: settingData, revalidate: getSettings, settingLoading } = usePlannerSettings(state.classId);

  const [allTopics, setAllTopics] = useState();
  const [frequencies, setFrequencies] = useState();
  const [days, setDays] = useState([]);
  const [holidays, setHolidays] = useState();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [showPreview, setShowPreview] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [frequency, setFrequency] = useState();
  const [allowedDays, setAllowedDays] = useState();
  const [blockedDates, setBlockedDates] = useState();
  const [topics, setTopics] = useState();

  const [myEvents, setMyEvents] = useState([]);
  const allTopicsChecked = allTopics?.length === topics?.length ?? false;
  const selectAllDisabled = allTopics?.length ? allTopics?.find(topic => topic.is_locked) : true;

  const previewPlanner = async () => {
    if (topics.length === 0) {
      message.error(t('new_planner_at_least_one_topic'));
      return;
    } else if (allowedDays.length === 0) {
      message.error(t('new_planner_at_least_one_day'));
      return;
    } else if (frequency === 'TWICE_WEEK' && allowedDays.length !== 2) {
      message.error(t('new_planner_two_days'));
      return;
    }
    setShowPreview({
      topics: topics,
      blocked_dates: [...blockedDates, ...holidays.map(holiday => holiday.date)],
      allowed_days: allowedDays,
      frequency: frequency,
      start_date: startDate,
      end_date: endDate,
      grade_id: grades.find(g => g.name === grade).id
    });
  };

  const createPlanner = async () => {
    if (topics.length === 0) {
      message.error(t('new_planner_at_least_one_topic'));
      return;
    } else if (allowedDays.length === 0) {
      message.error(t('new_planner_at_least_one_day'));
      return;
    } else if (frequency === 'TWICE_WEEK' && allowedDays.length !== 2) {
      message.error(t('new_planner_two_days'));
      return;
    }
    setButtonSpinner(true);
    const { success } = await postApiWithAuth(`${API_URL.PLANNER_EVENT}/${state.classId}`, {
      topics: topics,
      blocked_dates: [...blockedDates, ...holidays.map(holiday => holiday.date)],
      allowed_days: allowedDays,
      frequency: frequency,
      start_date: startDate,
      end_date: endDate,
      grade_id: grades.find(g => g.name === grade).id,
      events: myEvents
    });
    if (success) {
      setButtonSpinner(false);
      navigate('/planner');
    } else {
      setButtonSpinner(false);
    }
  };

  const fetchEndDate = async () => {
    if (
      topics.length === 0 ||
      allowedDays.length === 0 ||
      (frequency === 'TWICE_WEEK' && allowedDays.length !== 2) ||
      !grade
    ) {
      return;
    }
    const { success, data: endDateResponse } = await postApiWithAuth(`${API_URL.PLANNER_ENDDATE}/${state.classId}`, {
      topics: topics,
      blocked_dates: [...blockedDates, ...holidays.map(holiday => holiday.date)],
      allowed_days: allowedDays,
      frequency: frequency,
      start_date: startDate,
      grade_id: grades.find(g => g.name === grade).id
    });
    if (success) {
      setEndDate(endDateResponse['end_date']);
    } else {
      message.error(endDateResponse.message);
    }
  };

  const onChangeStartDateHandle = e => {
    const startDate = dayjs(e._d).format('YYYY-MM-DD');
    setStartDate(startDate);
  };

  const onChangeEndDateHandle = e => {
    const endDate = dayjs(e._d).format('YYYY-MM-DD');
    setEndDate(endDate);
  };

  const handleTopCheckAllChange = e => {
    const { checked } = e.target;
    const selectedTopics = allTopics?.map(topic => topic.value);
    setTopics(checked ? selectedTopics : []);
  };

  const handleTopicChange = e => {
    const { value, checked } = e.target;
    if (checked) {
      setTopics([...topics, value]);
    } else {
      setTopics([...topics.filter(item => item !== value)]);
    }
  };

  const handleFrequencyChange = e => {
    const { value } = e.target;
    setFrequency(value);
  };

  const handleDaysChange = e => {
    const { value, checked } = e.target;
    if (checked) {
      setAllowedDays([...allowedDays, value]);
    } else {
      setAllowedDays([...allowedDays.filter(item => item !== value)]);
    }
  };

  const selectGrade = gradeName => {
    setGrade(gradeName);
  };

  const handleDayChange = e => {
    const { value } = e.target;
    setAllowedDays([value]);
  };

  useEffect(async () => {
    setPlannerLoading(true);
    const { success, data: planner } = await getApiWithAuth(`${API_URL.PLANNER_CALENDAR}/${state.classId}`);
    if (success) {
      if (planner) {
        setTopics([...new Set(planner['topics'].filter(topic => !topic.is_locked).map(topic => topic.id))]);
        setEndDate(planner['end_date']);
        setStartDate(planner['start_date']);
        setAllowedDays(planner['allowed_days']);
        setGrade(planner['grade'].name);
        setFrequency(planner['frequency']);
        setBlockedDates(planner['blocked_dates']);
      }
      setPlannerLoading(false);
    } else {
      setPlannerLoading(false);
    }
  }, [state.classId]);

  useEffect(() => {
    setMyEvents([]);
    if (holidays && blockedDates) {
      fetchEndDate();
    }
  }, [startDate, topics, allowedDays, frequency, blockedDates, holidays, grade]);

  useEffect(() => {
    if (settingData) {
      setAllTopics(
        settingData['all_topics'].map(topic => ({
          label: topic.name,
          value: topic.id,
          lesson_count: topic.lesson_count,
          ...topic
        }))
      );
      setFrequencies(settingData['frequencies'].map(frequency => ({ label: frequency.name, value: frequency.id })));
      setDays(settingData['days'].map(day => ({ label: day.name, value: day.id })));
      setHolidays(settingData['off_days']);
    }
  }, [settingData]);

  useEffect(() => {
    if (grade) getSettings();
  }, [grade]);

  return (
    <div className="container-fluid p-2 p-md-3 p-lg-4 overViewMianDivHeight">
      <AuthenticatedNavbar smallHeading2={`${t('planner')}`} smallHeading3={`${t('setup')}`} />
      <Stack direction="column" alignItems="flex-start" spacing={30} style={{ marginTop: 22 }}>
        <Title level={2}>{t('set_up_planner')}</Title>
        {settingLoading || plannerLoading ? (
          <Card width="100%">
            <Stack direction="column" justifyContent="center" style={{ minHeight: '200px' }}>
              <Spin size="large" />
            </Stack>
          </Card>
        ) : (
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 14 }} xl={{ span: 15 }}>
              <Stack direction="column" spacing={30}>
                <Text size="small" style={{ color: Colors.GREY_100 }}>
                  {t('new_planner_detail').replace('[CLASSGRADE]', grade)}
                </Text>
                <Form form={form} gap={30} data-testid='new-planner-form'>
                  <Stack direction="column" alignItems="flex-start" spacing={30}>
                    <Form.Item data-testid='new-planner-grade' label={`${t('select_grade')}:`}>
                      <Select onChange={selectGrade} value={grade} name="grade">
                        {grades.map(item => (
                          <Option key={item.name} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Stack>
                      <Form.Item data-testid='new-planner-start-date' label={`${t('select_start_date')}:`}>
                        {startDate && (
                          <DatePicker
                            value={moment(startDate, 'YYYY - MM - DD')}
                            placeholder=""
                            suffixIcon={false}
                            name="start_date"
                            onChange={onChangeStartDateHandle}
                            format="YYYY-MM-DD"
                          />
                        )}
                      </Form.Item>
                      {endDate && (
                        <Form.Item
                          data-testid='new-planner-end-date'
                          label={`${t('select_end_date')}:`}
                          tooltip={{
                            overlayInnerStyle: { padding: '10px 16px', borderRadius: '5px' },
                            color: Colors.VIOLET,
                            title: t('planner_end_date_tooltip'),
                            icon: <HelpCircleIcon size={20} color={Colors.WHITE_0} fill={Colors.VIOLET} />
                          }}>
                          <DatePicker
                            value={moment(endDate, 'YYYY - MM - DD')}
                            placeholder=""
                            suffixIcon={false}
                            name="end_date"
                            onChange={onChangeEndDateHandle}
                            format="YYYY-MM-DD"
                          />
                        </Form.Item>
                      )}
                    </Stack>
                    {frequency && frequencies && (
                      <Form.Item data-testid='new-planner-frequency' label={`${t('new_planner_frequency_label')}:`}>
                        <Radio.Group onChange={handleFrequencyChange} defaultValue={frequency} options={frequencies} />
                      </Form.Item>
                    )}
                    {frequency && days && allowedDays && (
                      <Form.Item data-testid='new-planner-days' label={`${t('new_planner_days_label')}:`}>
                        {frequency === 'WEEKLY' ? (
                          <Radio.Group onChange={handleDayChange} defaultValue={allowedDays[0]} options={days} />
                        ) : (
                          <>
                            {days.map(day => (
                              <Checkbox
                                value={day.value}
                                key={day.value}
                                checked={allowedDays.find(item => item === day.value)}
                                onChange={handleDaysChange}
                                disabled={allowedDays.length === 2 && !allowedDays.find(item => item === day.value)}>
                                {day.label}
                              </Checkbox>
                            ))}
                          </>
                        )}
                      </Form.Item>
                    )}
                    {topics && allTopics && (
                      <Form.Item
                        data-testid='new-planner-topics'
                        label={`${t('new_planner_topics_label').replace('[CLASSGRADE]', currentClass.grade)}:`}>
                        <Checkbox
                          onChange={handleTopCheckAllChange}
                          disabled={selectAllDisabled}
                          checked={allTopicsChecked}>
                          {t('select_all')}
                        </Checkbox>
                        <Checkbox.Group value={topics} style={{ width: '100%' }}>
                          {allTopics.map(topic => (
                            <Checkbox
                              style={{ width: '100%' }}
                              onChange={handleTopicChange}
                              name="all_topics"
                              disabled={topic.is_locked}
                              value={topic.value}
                              key={topic.value}>
                              <Stack justifyContent="space-between">
                                <Text size="small">
                                  {topic.label}
                                  {topic.lesson_count && (
                                    <Text size="small" style={{ color: Colors.GREY_50 }}>
                                      &nbsp;({topic.lesson_count} {t('lesson')})
                                    </Text>
                                  )}
                                </Text>
                                <Badge type={getTopic(topic.sub_heading.name)}>
                                  {teacherT(getTopic(topic.sub_heading.name))}
                                </Badge>
                              </Stack>
                            </Checkbox>
                          ))}
                        </Checkbox.Group>
                      </Form.Item>
                    )}
                  </Stack>
                </Form>
              </Stack>
            </Col>
            <Col
              xs={{ span: 16, offset: 0 }}
              md={{ span: 16, offset: 0 }}
              lg={{ span: 9, offset: 1 }}
              xl={{ span: 8, offset: 1 }}>
              <Card>
                <Stack direction="column" alignItems="flex-start" spacing={20}>
                  <Title level={3}>{t('summary')}</Title>
                  <Stack direction="column" alignItems="flex-start">
                    <Text size="large" strong>
                      {currentClass?.name}
                    </Text>
                    <Stack justifyContent="space-between">
                      <Text size="small"> Start: {startDate} </Text>
                      <Text size="small"> End: {endDate} </Text>
                    </Stack>
                    <Text size="small">{grade}</Text>
                    <Text size="small">{frequencies?.find(item => item.value === frequency)?.label}</Text>
                    <Text size="small">
                      {allowedDays?.reduce(
                        (accumulator, day) =>
                          accumulator
                            ? `${accumulator}, ${days.find(item => item.value === day)?.label}`
                            : days.find(item => item.value === day)?.label,
                        ''
                      )}
                    </Text>
                    <Text size="small">
                      {topics?.reduce(
                        (accumulator, topic) =>
                          accumulator
                            ? `${accumulator}, ${allTopics.find(item => item.value === topic)?.label}`
                            : allTopics.find(item => item.value === topic)?.label,
                        ''
                      )}
                    </Text>
                  </Stack>
                  <Stack direction="column">
                    <Button data-testid='planner-preview-button' type="secondary" block onClick={previewPlanner}>
                      {t('planner_preview')}
                    </Button>
                    <Button data-testid='planner-setup-button' type="primary" block onClick={createPlanner} loading={buttonSpinner}>
                      {t('set_up')}
                    </Button>
                  </Stack>
                </Stack>
              </Card>
            </Col>
          </Row>
        )}
      </Stack>
      <PlannerPreview
        onCancel={() => {
          setShowPreview(null);
        }}
        setEvents={events => {
          setMyEvents(events);
        }}
        data={showPreview}
        show={showPreview !== null}
      />
    </div>
  );
};
export default NewPlanner;
