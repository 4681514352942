import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import SupportHelpfulLinks from '../../components/SupportHelpfulLinks';
import { AuthenticatedNavbar } from '@components/layoutComponents';
import { Text } from '@student-app/design-system';
import FAQs from '@teacher-app/pages/Support/FAQs';

const Support = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'support' });

  return (
    <div data-testid="teacher-support-page" className="container-fluid p-2 p-md-3 p-lg-4 overViewMianDivHeight">
      <AuthenticatedNavbar title={t('support')} />
      <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
        <Col span={16} md={16} sm={24} xs={24}>
          <Text size="small">{t('faq_intro')}</Text>
        </Col>
        <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
          <Col span={16} md={16} sm={24} xs={24}>
            <FAQs />
          </Col>
          <Col span={8} md={8} sm={24} xs={24}>
            <SupportHelpfulLinks />
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default Support;
