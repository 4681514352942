import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, message, Select, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, Stack, Text, Title } from '@parent-app/design-system';

import { useGrades } from '@hooks';
import { API_URL, postApiWithAuth } from '@utils';

const { Option } = Select;

const AddChildModal = ({ isOpen, onClose, handleOnFinish }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'parents' });
  const [form] = Form.useForm();
  const { data: grades } = useGrades();

  const [showSpinner, setShowSpinner] = useState(false);

  const handleSubmit = async values => {
    setShowSpinner(true);
    const { success, data } = await postApiWithAuth(API_URL.PARENT_CREATE_CHILD_ACCOUNT_NEW, values);
    if (success) {
      handleOnFinish(data);
      form.resetFields();
    } else {
      message.error(data?.message);
    }
    setShowSpinner(false);
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal width={585} centered={false} onCancel={onCancel} open={isOpen}>
      <Form form={form} onFinish={handleSubmit}>
        <Stack direction="column" spacing={22}>
          <Stack direction="column" alignItems="flex-start" spacing={12}>
            <Title level={3}>{t('add_child')}</Title>
            <Text size="small">{t('add_child_details')}</Text>
            <Row style={{ width: '100%' }} justify="space-between">
              <Col span={15}>
                <Form.Item
                  label={`${t('child_name')}:`}
                  rules={[
                    { required: true, message: t('name_required') },
                    () => ({
                      validator(_, value) {
                        const pattern =/^[A-Za-z]+ [A-Za-z]$/;
                        const error = !pattern.test(value);
                        if (value?.length && error) {
                          return Promise.reject(new Error(t('first_letter_error')));
                        }
                        return Promise.resolve();
                      }
                    })
                  ]}
                  required
                  name="name">
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={`${t('grade')}:`} rules={[{ required: true, message: t('required')  }]} required name="grade">
                  <Select name="grade">
                    {grades.map(item => (
                      <Option key={item.name} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={`${t('student_email')}:`}
              rules={[
                {
                  type: 'email',
                  message: t('invalid_email')
                }
              ]}
              name="student_email">
              <Input type="email" />
            </Form.Item>
          </Stack>
          <Stack direction="column" spacing={12}>
            <Button block htmlType="submit" disabled={showSpinner} loading={showSpinner}>
              {t('create_child')}
            </Button>
          </Stack>
        </Stack>
      </Form>
    </Modal>
  );
};

export default AddChildModal;

AddChildModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleOnFinish: PropTypes.func.isRequired
};
