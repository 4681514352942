import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@parent-app/design-system';
import PageLayout from '@parent-app/components/PageLayout';
import HelpfulLinks from '@parent-app/components/HelpfulLinks';
import useSWR from 'swr';
import { API_URL, fetcher } from '@utils';
import DeleteAccount from './DeleteAccount';
import ChildList from './ChildList';

const ChildAccount = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'parents' });
  const { data, isLoading } = useSWR(`${API_URL.PARENT_CHILDREN_PAGE}?page=1`, fetcher);
  const { results } = data?.data || {};

  return (
    <PageLayout title={ t('child_account')}>
      <Row>
        <Col span={16}>
          <Text size='small'>
            {t('child_account_description')}
          </Text>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 23 }}>
        <Col span={16}>
          <Stack direction="column" spacing={33}>
            <ChildList />
            <DeleteAccount />
          </Stack>
        </Col>
        <Col span={8}>
          <Stack direction="column" spacing={33}>
            <HelpfulLinks loading={isLoading} hasChildren={results?.length > 0} />
          </Stack>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default ChildAccount;