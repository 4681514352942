import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, message, Skeleton } from 'antd';
import { Copy, Download } from 'react-feather';
import { Button, Colors, Divider, Form, Modal, Stack, Text, Title, Link } from '@parent-app/design-system';
import { useTranslation } from 'react-i18next';
import { API_URL, getApiWithAuth, patchApiWithAuth } from '@utils';
import { LoginInstructionsPdf } from './LoginInstructionsPdf';

const ChildLoginDetailModal = ({ isOpen, onClose, selectedLoginDetails }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'parents' });
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [loginDetail, setLoginDetail] = useState(null);

  const fetchUserDetails = async () => {
    setLoading(true);
    const { success, data } = await getApiWithAuth(
      `${API_URL.GET_CHILD_DETAILS_BY_ID}/${selectedLoginDetails?.id}`
    );

    if (success) {
      setLoginDetail({
        first_name: selectedLoginDetails?.first_name,
        last_name: selectedLoginDetails?.last_name,
        ...data
      });
      setLoading(false);
    } else {
      message.error(data.message);
      setLoading(false);
    }
  };

  useEffect (async () => {
    if (!selectedLoginDetails) return;
    await fetchUserDetails();
  }, [selectedLoginDetails]);

  const onSave = async ({ newPassword  }) => {
    if (!selectedLoginDetails) return;
    const input ={
      current_password: loginDetail?.password,
      student_id: selectedLoginDetails?.id,
      user_name: selectedLoginDetails?.username,
      new_password: newPassword,
      re_type_password: newPassword
    };

    setUpdating(true);
    const { success, data } = await patchApiWithAuth(
      `${API_URL.RESET_CHILD_PASSWORD_V2}`, input
    );

    if (success) {
      await fetchUserDetails();
      setUpdating(false);
      message.success(t('password_updated'));
    } else {
      message.error(data.message);
      setUpdating(false);
    }
  };

  const copyToClipboard = async (classCode, key) => {
    try {
      await navigator.clipboard.writeText(classCode);
      message.success(t(`${key}_copied`));
    } catch (error) {
      message.error(t(`${key}_copy_failed`));
    }
  };

  useEffect(() => {
    if (!loading && selectedLoginDetails?.id) {
      form.setFieldsValue({
        username: loginDetail?.username,
        password: loginDetail?.password,
      });
    }
  }, [selectedLoginDetails?.id, loading]);

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal width={585} centered={false} onCancel={onCancel} open={isOpen}>
      <Form requiredMark={false} form={form} onFinish={onSave} initialValues={{ username: loginDetail?.username, password: loginDetail?.password }}>
        {loading ? (
          <Skeleton active />
        ) : (
          <Stack direction="column" spacing={22}>
            <Stack direction="column" alignItems="flex-start" spacing={12}>
              <Title level={3}>{t('login_modal_title')}</Title>
              <Text size="small">
                {t('credentials_modal_description_prefix')}&nbsp;
                <Link style={{ display: 'inline-block' }} onClick={() => window.open('/child-account', '_blank', 'noreferrer')} hideIcon>{t('cyber_academy')}</Link>
                ,&nbsp;{t('credentials_modal_description_suffix')}
              </Text>
              <Form.Item label={`${t('username')}:`} name="username">
                <Input
                  type="text"
                  name="username"
                  readOnly
                  onChange={e => e.preventDefault()}
                  suffix={
                    <Copy
                      style={{ cursor: 'pointer', color: Colors.VIOLET }}
                      onClick={() => copyToClipboard(loginDetail?.username, 'username')}
                    />
                  }
                />
              </Form.Item>
              <Form.Item label={`${t('password')}:`} name="password">
                <Input
                  type="text"
                  name="password"
                  readOnly
                  suffix={
                    <Copy
                      style={{ cursor: 'pointer', color: Colors.VIOLET }}
                      onClick={() => copyToClipboard(loginDetail?.password, 'password')}
                    />
                  }
                />
              </Form.Item>
              <Stack>
                <Divider>
                  <Text size='x-small'>{t('or')} <Text bold>{t('update_password')}</Text></Text>
                </Divider>
              </Stack>
              <Form.Item
                label={`${t('new_password')}:`}
                name="newPassword"
                rules={[
                  { required: true, message: t('password_required') },
                  () => ({
                    validator(_, value) {
                      const pattern =/^(?=.*[A-Z])(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
                      const error = !pattern.test(value);
                      if (value?.length && error) {
                        return Promise.reject(new Error(t('weak_password')));
                      }
                      return Promise.resolve();
                    }
                  })
                ]} >
                <Input.Password
                  type="password"
                  name="newPassword"
                />
              </Form.Item>
            </Stack>
            <Stack direction='column' spacing={20}>
              <Button htmlType='submit' block loading={updating}>{t('save')}</Button>
              {loginDetail && <Link size='medium' icon={<Download />} style={{ padding: 15 }} block><LoginInstructionsPdf loginDetail={loginDetail} /></Link>}
            </Stack>
          </Stack>
        )}
      </Form>
    </Modal>
  );
};

export default ChildLoginDetailModal;

ChildLoginDetailModal.defaultProps = {
  selectedLoginDetails: null
};

ChildLoginDetailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedLoginDetails: PropTypes.object
};