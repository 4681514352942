import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, message, Skeleton } from 'antd';
import { Copy, Download } from 'react-feather';
import { Button, Colors, Form, Modal, Stack, Text, Title, Link } from '@parent-app/design-system';
import { useTranslation } from 'react-i18next';
import { API_URL, getApiWithAuth } from '@utils';
import { LoginInstructionsPdf } from '@parent-app/components/ChildLoginDetailModal/LoginInstructionsPdf';

const AddChildSuccessModal = ({ isOpen, onClose, selectedLoginDetails }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'parents' });
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [loginDetail, setLoginDetail] = useState(null);

  useEffect(async () => {
    if (!selectedLoginDetails) return;
    setLoading(true);
    const { success, data } = await getApiWithAuth(
      `${API_URL.GET_CHILD_DETAILS_BY_ID}/${selectedLoginDetails?.id}`
    );

    if (success) {
      setLoginDetail({
        first_name: selectedLoginDetails?.first_name,
        last_name: selectedLoginDetails?.last_name,
        ...data
      });
      setLoading(false);
    } else {
      message.error(data.message);
      setLoading(false);
    }
  }, [selectedLoginDetails]);

  const copyToClipboard = async (classCode, key) => {
    try {
      await navigator.clipboard.writeText(classCode);
      message.success(t(`${key}_copied`));
    } catch (error) {
      message.error(t(`${key}_copy_failed`));
    }
  };

  useEffect(() => {
    if (!loading && selectedLoginDetails?.id) {
      form.setFieldsValue({
        username: loginDetail?.username,
        password: loginDetail?.password,
      });
    }
  }, [selectedLoginDetails?.id, loading]);

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal width={585} centered={false} onCancel={onCancel} open={isOpen}>
      <Form form={form} onFinish={onClose} initialValues={{ username: loginDetail?.username, password: loginDetail?.password }}>
        {loading ? (
          <Skeleton active />
        ) : (
          <Stack direction="column" spacing={22}>
            <Stack direction="column" alignItems="flex-start" spacing={12}>
              <Title level={3}>{t('account_created')}</Title>
              <Text size="small">
                {t('account_created_description')}
              </Text>
              <Form.Item label={`${t('username')}:`} name="username">
                <Input
                  type="text"
                  name="username"
                  readOnly
                  onChange={e => e.preventDefault()}
                  suffix={
                    <Copy
                      style={{ cursor: 'pointer', color: Colors.VIOLET }}
                      onClick={() => copyToClipboard(loginDetail?.username, 'username')}
                    />
                  }
                />
              </Form.Item>
              <Form.Item label={`${t('password')}:`} name="password">
                <Input
                  type="text"
                  name="password"
                  readOnly
                  suffix={
                    <Copy
                      style={{ cursor: 'pointer', color: Colors.VIOLET }}
                      onClick={() => copyToClipboard(loginDetail?.password, 'password')}
                    />
                  }
                />
              </Form.Item>
            </Stack>
            <Stack direction='column' spacing={20}>
              <Button htmlType='submit' block>{t('thats_it')}</Button>
              {loginDetail && <Link size='medium' icon={<Download />} style={{ padding: 15 }} block><LoginInstructionsPdf loginDetail={loginDetail} /></Link>}
            </Stack>
          </Stack>
        )}
      </Form>
    </Modal>
  );
};

export default AddChildSuccessModal;

AddChildSuccessModal.defaultProps = {
  selectedLoginDetails: null
};

AddChildSuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedLoginDetails: PropTypes.object
};
