import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Spin, Empty, Row, Col } from 'antd';

import { Pagination } from '@components/commonComponents';
import { API_URL, getApiWithAuth } from '@utils';
import { useGrades } from '@hooks';
import PageLayout from '@parent-app/components/PageLayout';
import { Button, Form, Stack, Text } from '@parent-app/design-system';
import ParentResourceCard from '@parent-app/components/ParentResourceCard/ParentResourceCard';

const { Option } = Select;

const Activities = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'parents' });

  const { data: grades } = useGrades();

  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resourcesData, setResourcesData] = useState([]);
  const [dataGrade, setDataGrade] = useState('All');
  const [dataSkill, setDataSkill] = useState('All');

  const itemsPerPage = 8;

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const getFilterData = async () => {
    if (dataGrade === 'All' && dataSkill === 'All') {
      await getResources();
    } else if (dataGrade === 'All') {
      setButtonSpinner(true);
      const { success, data } = await getApiWithAuth(`${API_URL.GET_PARENT_RESOURCES}?topic=${dataSkill}`);
      if (success) {
        setResourcesData(data);
      }
      setButtonSpinner(false);
    } else if (dataSkill === 'All') {
      const { success, data } = await getApiWithAuth(`${API_URL.GET_PARENT_RESOURCES}?grade=${dataGrade}`);
      if (success) {
        setResourcesData(data);
      }
      setButtonSpinner(false);
    } else {
      setButtonSpinner(true);
      const { success, data } = await getApiWithAuth(
        `${API_URL.GET_PARENT_RESOURCES}?grade=${dataGrade}&topic=${dataSkill}`
      );
      if (success) {
        setResourcesData(data);
      }
      setButtonSpinner(false);
    }
  };

  const getResources = async () => {
    const { success, data } = await getApiWithAuth(API_URL.GET_PARENT_RESOURCES);
    if (success) {
      setResourcesData(data);
      setButtonSpinner(false);
    }
  };

  const handleReset = () => {
    setDataGrade('All');
    setDataSkill('All');
  };

  useEffect(() => {
    getFilterData();
  }, [dataGrade, dataSkill]);

  const totalPages = Math.ceil(resourcesData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = useMemo(
    () => resourcesData.slice(indexOfFirstItem, indexOfLastItem),
    [resourcesData, indexOfFirstItem, indexOfLastItem]
  );

  return (
      <PageLayout title={ t('activity_kit')} cyberAcademyButton>
        <Stack direction='column' alignItems='flex-start' spacing={30}>
          <Col span={16}>
            <Text size='small'>
              {t('activity_kit_description')}
            </Text>
          </Col>
          <Form>
            <Stack style={{ flexWrap: 'wrap' }}>
              <Form.Item style={{ flex: '1 1 300px' }}>
                <Select value={dataSkill} onChange={(skill) => setDataSkill(skill)} name="skill">
                  <Option key={-1} value={'All'}>
                    {t('select_skill')}
                  </Option>
                  {/* Replace with parent api endpoint */}
                  <Option key="Be Cyber Safe" value="Be Cyber Safe">
                    Be Cyber Safe
                  </Option>
                  <Option key="Be Cyber Positive " value="Be Cyber Positive ">
                    Be Cyber Positive
                  </Option>
                  <Option key="Be Cyber Secure" value="Be Cyber Secure">
                    Be Cyber Secure
                  </Option>
                  <Option key="Be Cyber Informed" value="Be Cyber Informed">
                    Be Cyber Informed
                  </Option>
                  <Option key="Be Cyber Kind" value="Be Cyber Kind">
                    Be Cyber Kind
                  </Option>
                  <Option key="Be Cyber Healthy" value="Be Cyber Healthy">
                    Be Cyber Healthy
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item style={{ flex: '1 1 300px' }}>
                <Select value={dataGrade} onChange={(grade) => setDataGrade(grade)} name="grade">
                  <Option key={-1} value={'All'}>
                    {t('select_grade')}
                  </Option>
                  {grades.map(item => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Button style={{ padding: '14px 25px !important', height: '50px' }} onClick={handleReset}>
                {t('reset')}
              </Button>
            </Stack>
          </Form>
          {buttonSpinner ? (
            <Stack direction="column" justifyContent="center" style={{ minHeight: '300px' }}>
              <Spin size="large" />
            </Stack>
          ) : resourcesData?.length === 0 ? (
            <Stack direction="column" justifyContent="center" style={{ minHeight: '300px' }}>
              <Empty description={false} />
            </Stack>
          ) : (
            <Stack direction="column">
              <Row style={{ width: '100%' }} gutter={[16, 16]}>
                {currentItems?.map((item, index) => {
                  return (
                    <Col lg={6} md={8} sm={12} xs={24} key={`${item.id}-${index}`}>
                      <ParentResourceCard resourceItem={item} />
                    </Col>
                  );
                })}
              </Row>
              <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </Stack>
          )}
        </Stack>
    </PageLayout>
  );
};
export default Activities;
