export const getTopic = (name) => {
  switch (name) {
    case 'Be Cyber Kind':
      return 'cyber-kind';
    case 'Soyez cyber-gentils':
      return 'cyber-kind';
    case 'Be Cyber Safe':
      return 'cyber-safe';
    case 'Soyez en sécurité sur Internet':
      return 'cyber-safe';
    case 'Be Cyber Healthy':
      return 'cyber-healthy';
    case 'Be Cyber Positive':
      return 'cyber-positive';
    case 'Soyez cyber-positif':
      return 'cyber-positive';
    case 'Be Cyber Informed':
      return 'cyber-informed';
    case 'Be Cyber Secure':
      return 'cyber-secure';
    default:
      return 'warning';
  }
};
