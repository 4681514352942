import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, message, Radio } from 'antd';

import { Multistep, Navbar } from '@components/layoutComponents';
import { EducatorButton } from '@components/commonComponents';
import { useCheckoutFlow } from '@hooks';
import { postApiWithAuth, API_URL } from '@utils';

import tickImage from '@assets/images/tick.svg';

import './SelectLicence.scss';

const LicenceOption = ({ title, price, features, isPremium, isSelected }) => {
  const radioId = isPremium ? 'premium' : 'free';

  return (
    <label id={radioId} className={`licence-option__container ${radioId} ${isSelected ? 'selected' : ''}`}>
      <div className="licence-option__header">
        <div className="d-flex">
          <Radio id={radioId} value={radioId} className="licence-option__radio">
            <h2 className="licence-option__title">{title}</h2>
          </Radio>
        </div>
        <div className="d-flex justify-content-center">
          <p className="licence-option__price">{price}</p>
          {isPremium ? <span className="align-self-center premium-year">/YR</span> : null}
        </div>
      </div>
      <ul className="licence-option__features">
        {features.map((feature, index) => (
          <li key={index} className="licence-option__feature">
            <img src={tickImage} alt="check" />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
    </label>
  );
};

export const SelectLicence = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'select_licence' });
  const navigate = useNavigate();
  const [value, setValue] = useState('free');
  const { executeCheckoutFlow, loading, checkoutData, checkoutError } = useCheckoutFlow();

  const handleRadioChange = e => {
    setValue(e.target.value);
  };

  const handleOnFinish = async () => {
    if (value === 'free') {
      await postApiWithAuth(API_URL.PAYMENTS.CREATE_FREE_TEACHER_LICENSE);
      navigate('/teacher-create-account/signed-up');
    } else {
      await executeCheckoutFlow();
    }
  };

  if (checkoutError) {
    message.error(checkoutError);
  }

  if (checkoutData) {
    window.location.href = checkoutData;
  }

  return (
    <div className="select-licence__container mainDivSignUpStyle container-fluid">
      <Navbar />
      <div className="row py-5 mt-5">
        <div className="col-3 d-flex justify-content-center">
          <Multistep select="licence" check={[1, 1, 0, 1]} />
        </div>
        <div className="col-6 col-md-5 col-lg-6 d-flex justify-content-center flex-column">
          <Form onFinish={handleOnFinish}>
            <div className="select-licence__title textStyleHeading mb-4">{t('title')}</div>
            <Form.Item className="d-flex">
              <Radio.Group onChange={handleRadioChange} defaultValue={'free'} className="select-licence__options">
                <div className="col col-md-6">
                  <LicenceOption
                    title={t('regular_member')}
                    price={t('free')}
                    features={[
                      t('teacher_dashboard'),
                      `${t('curriculum_alignment')} (${t('one_grade_only')})`,
                      `${t('cyber_safety')} (${t('one_grade_only')})`,
                      `${t('reporting')} (${t('one_grade_only')})`
                    ]}
                    isSelected={value === 'free'}
                  />
                </div>
                <div className="col col-md-6">
                  <LicenceOption
                    title={t('premium_member')}
                    price={t('price')}
                    features={[
                      t('teacher_dashboard'),
                      t('professional_development'),
                      t('curriculum_alignment'),
                      t('260_missions'),
                      t('reporting')
                    ]}
                    isPremium
                    isSelected={value === 'premium'}
                  />
                </div>
              </Radio.Group>
            </Form.Item>

            <div className="select-licence__continue">
              <Form.Item>
                <EducatorButton
                  className="select-licence__continue-button"
                  label={t('button_text')}
                  type="submit"
                  loading={loading}
                  dataTestId="select-licence-continue-button"
                />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="col-3 pb-3 d-flex align-items-end" />
      </div>
    </div>
  );
};

LicenceOption.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
  isPremium: PropTypes.bool,
  isSelected: PropTypes.bool
};

LicenceOption.defaultProps = {
  isPremium: false,
  isSelected: false
};
