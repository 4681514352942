import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Divider as AntDivider } from 'antd';
import colors from '../../theme/colors';

const StyledDivider = styled(AntDivider)` 
  &.ant-divider-horizontal.ant-divider-with-text:after, &.ant-divider-horizontal.ant-divider-with-text:before {
      border-width: ${props => props.borderwidth}px;
      border-color: ${props => props.bordercolor};
  }
`;

const Divider = ({ children, ...rest }) => {
  return (
    <StyledDivider {...rest}>
      {children}
    </StyledDivider>
  );
};

export default Divider;

Divider.defaultProps = {
  children: null,
  borderwidth: 2,
  bordercolor: colors.LAVENDER_50
};

Divider.propTypes = {
  children: PropTypes.node,
  borderwidth: PropTypes.number,
  bordercolor: PropTypes.string,
};
