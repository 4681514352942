import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'antd';
import { Title, Stack, Text, Colors, Badge, Button } from '@parent-app/design-system';

import { getDataTestId, getMemberShipType } from '@utils';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { getTopic } from '@parent-app/utils/getTopic';
import { Lock as LockIcon } from 'react-feather';
import UnlockParentResourceModal from '../UnlockParentResourceModal';
import { useTranslation } from 'react-i18next';

const StyledCard = styled.div`
  border-radius: 15px;
  overflow: hidden;
  display: block;
  min-width: 200px;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
  padding: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: ${Colors.WHITE_0};
    & .resource-thumbnail:hover {
        .lock-icon {
            display: none;
        }
        .unlock-button {
            display: block;
        }
    }
    & .resource-thumbnail {
        .unlock-button {
            display: none;
        }
    }
`;

const StyledText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ParentResourceCard = React.memo(({ resourceItem }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'parents' });
  const { id, title, description, thumbnail, topic, allow_fremium } = resourceItem;
  const [showLockedModal, setShowLockedModal] = React.useState(false);
  const navigate = useNavigate();

  const handleCardClick = () => {
    if(!allow_fremium) return null;
    navigate(`/parent-resource/${id}`, {
          state: {
            itemId: id,
            isAllowFremium: allow_fremium,
            isMemberShipType: getMemberShipType(),
            title: title
          }
        });
  };

  const handleUnlock = () => {
    if (allow_fremium) return;
    setShowLockedModal(true);
  };

  return (
    <>
    <StyledCard onClick={handleCardClick} data-testid={getDataTestId(name)}>
      <Stack direction="row" className="position-relative resource-thumbnail">
        <Image width={'100%'} height={150} src={thumbnail} alt="resourcesImage" preview={false} />
        <Stack
          display={!allow_fremium ? 'flex' : 'none'}
          justifyContent="center"
          style={{ background: 'rgba(38, 38, 41, 0.70)' }}
          className="position-absolute h-100">
          <LockIcon className="lock-icon" color={'#CCC8C7'} size={82} />
          <Button data-testid="unlockButton" className="unlock-button" onClick={handleUnlock}>
            {t('unlock')}
          </Button>
        </Stack>
      </Stack>
      <Stack direction="column" alignItems="flex-start" style={{ padding: '24px 16px' }}>
        <Title ellipsis style={{ width: '100%' }}>
          {title}
        </Title>
        <StyledText size="x-small">{description}</StyledText>
        <Badge type={getTopic(topic?.text)}>{topic?.text}</Badge>
      </Stack>
    </StyledCard>
    <UnlockParentResourceModal isOpen={showLockedModal} onClose={() => setShowLockedModal(false)} />
    </>
  );
});

export default ParentResourceCard;

ParentResourceCard.propTypes = {
  resourceItem: PropTypes.object.isRequired,
};
