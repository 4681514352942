import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Button, Stack, Title, Text } from '../../design-system';

import { Image } from 'antd';
import notFoundImage from '@assets/images/icon_disappearingChat.png';

const NoPlanner = ({ onClick }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'planner' });
  return (
      <Card data-testid="no-planner-found" width="100%">
        <Stack direction="column" spacing={30}>
          <Stack direction="column">
            <Image preview={false} src={notFoundImage} alt="error" />
            <Title level={3}>{t('set_up_planner_empty')}</Title>
            <Text size="small">{t('set_up_planner_detail')}</Text>
          </Stack>
          <Button onClick={onClick}>{t('set_up')} </Button>
        </Stack>
      </Card>
  );
};

export default NoPlanner;

NoPlanner.propTypes = {
  onClick: PropTypes.func.isRequired
};
