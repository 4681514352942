import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Text, Badge, Colors } from '@teacher-app/design-system';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAssignmentStatus } from '@teacher-app/utils/getAssignmentStatus';

const StyledCard = styled.div`
  border-radius: 10px;
  overflow: hidden;
  display: block;
  width: 100%;
  align-items: flex-start;
  cursor: pointer;
  padding: 14px 20px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
  background-color: ${Colors.WHITE_0};
`;

const AssignmentCard = React.memo(({ assignmentItem }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });
  const { name, start_date, end_date, id } = assignmentItem;
  const navigate = useNavigate();

  return (
    <StyledCard data-testid={`assignment-card-${id}`} onClick={() => navigate(`/assignments/report/${id}`, { state: assignmentItem })}>
      <Stack spacing={10} direction="column" alignItems="flex-start">
        <Badge type={getAssignmentStatus(start_date, end_date, t).type}>{getAssignmentStatus(start_date, end_date, t).status}</Badge>
        <Text bold size="small" ellipsis>
          {name}
        </Text>
      </Stack>
    </StyledCard>
  );
});

export default AssignmentCard;

AssignmentCard.propTypes = {
  assignmentItem: PropTypes.object.isRequired
};
