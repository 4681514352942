import React, { useEffect } from 'react';
import { Button, Link, Stack, Text, Title } from '@teacher-app/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AssignmentCard from '@teacher-app/components/AssignmentCard';
import LoadingCards from '@teacher-app/components/LoadingCards';
import { useAppState } from '@context';
import { useAssignments } from '@hooks';
import dayjs from 'dayjs';

const Assignments = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const navigate = useNavigate();
  const { state } = useAppState();

  const { data: classAssignmentsData, revalidate: getAssignments, loading } = useAssignments(state.classId);
  const showMore = classAssignmentsData?.length > 5;

  const activeAssignments = classAssignmentsData?.filter(
    assignment => assignment.end_date >= dayjs().format('YYYY-MM-DD') && assignment?.status !== 'Canceled'
  );

  const hasAssignments = activeAssignments?.length > 0;

  useEffect(() => {
    getAssignments();
  }, [state.classId]);

  return (
    <Stack data-testid='assignments-overview' direction="column" spacing={10}>
      <Stack justifyContent="space-between">
        <Title level={3}>{t('assignments')}</Title>
        {showMore && <Link data-testid='assignment-view-all-button' onClick={() => navigate('/assignments')}>{t('view_all')}</Link>}
      </Stack>
      {loading ? (
        <Stack direction="column" spacing={10}>
          <LoadingCards paragraph={{ rows: 1 }} />
        </Stack>
      ) : (
        <>
          {hasAssignments && (
            <Stack direction="column" style={{ paddingTop: '10px' }}>
              {activeAssignments?.slice(0, 5)?.map((assignment, index) => (
                <AssignmentCard key={index} assignmentItem={assignment} />
              ))}
            </Stack>
          )}
          {!hasAssignments && (
            <Stack direction="column" alignItems="flex-start" spacing={20}>
              <Text size={'small'}>{t('no_assignment_text')}</Text>
              <Button data-testid='assignments-buttons' block onClick={() => navigate('/assignments')}>
                {t('assign_mission')}
              </Button>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};

export default Assignments;
