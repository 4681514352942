import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { Modal, Stack } from '@teacher-app/design-system';
import { useSchoolTutorial } from '@hooks';
import { Spin } from 'antd';
import { sendGaEvent } from '@utils';

const VideoWrapper = ({ children }) => (
  <Stack
    style={{
      borderRadius: 20,
      width: '100%',
      height: '100%',
      padding: 0,
      overflow: 'hidden'
    }}>
    {children}
  </Stack>
);

VideoWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

const TutorialVideoModal = ({ type, isOpen, onClose }) => {
  const { data: schoolTutorial, loading } = useSchoolTutorial(type);
  const videoRef = useRef(null);
  const [isPlaying, setPlaying] = useState(true);

  const video = schoolTutorial?.data
    ? {
        name: schoolTutorial?.data?.video_title,
        thumbnail: schoolTutorial?.data?.video_thumbnail,
        url: schoolTutorial?.data?.video_url
      }
    : null;

  return (
    <Modal data-testid='tutorial-video-player-modal' destroyOnClose width={'80vw'} open={isOpen} onCancel={onClose} closable>
      {loading ? (
        <Stack direction="column" justifyContent="center" style={{ height: '80vh' }}>
          <Spin size="large" />
        </Stack>
      ) : (
        <ReactPlayer
          width="100%"
          height="100%"
          controls
          ref={videoRef}
          url={video?.url}
          playing={isPlaying}
          wrapper={VideoWrapper}
          data-testid='tutorial-video-player'
          light={
            <Stack
              style={{
                width: '80vw',
                height: 'calc((80vw) * .5625)',
                backgroundSize: 'contain',
                backgroundImage: `url(${video?.thumbnail})`
              }}>
              {' '}
            </Stack>
          }
          onEnded={() => {
            setPlaying(false);
            sendGaEvent('video_ended', 'Video Ended', video?.name);
          }}
          onPlay={() => {
            setPlaying(true);
            sendGaEvent('video_play', 'Video Play', video?.name);
          }}
          onPause={() => {
            setPlaying(false);
            sendGaEvent('video_pause', 'Video Pause', video?.name);
          }}
        />
      )}
    </Modal>
  );
};

export default TutorialVideoModal;

TutorialVideoModal.propTypes = {
  type: PropTypes.oneOf(['SetupPlannerDetail', 'AddingStudents']).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
