import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import HelpfulLinks from './HelpfulLinks';
import { AuthenticatedNavbar } from '@components/layoutComponents';
import { Stack } from '@teacher-app/design-system';
import CreateStudent from '@teacher-app/components/CreateStudent';
import ClassCodeCopyCard from '@teacher-app/components/ClassCodeCopyCard';
import StudentsList from '@teacher-app/components/StudentsList/StudentsList';


const Students = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });

  return (
    <div data-testid="students-page" className="container-fluid p-2 p-md-3 p-lg-4 overViewMianDivHeight">
      <AuthenticatedNavbar title={t('students')} />
      <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
        <Col span={24} md={24} sm={24}>
          <CreateStudent />
        </Col>
        <Col span={16} md={16} sm={24}>
          <StudentsList />
        </Col>
        <Col span={8} md={8} sm={24}>
          <Stack alignItems="flex-start" direction="column" spacing={20}>
            <ClassCodeCopyCard />
            <HelpfulLinks />
          </Stack>
        </Col>
      </Row>
    </div>
  );
};

export default Students;
