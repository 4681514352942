import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin, Empty, Row, Col } from 'antd';

import { AuthenticatedNavbar } from '@components/layoutComponents';
import { Pagination } from '@components/commonComponents';
import { Stack, Text } from '@teacher-app/design-system';
import { API_URL, getApiWithAuth } from '@utils';
import EdubyteCard from '../../components/EdubyteCard';

export const Edubytes = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'teaching_resources' });
  const [edubytesData, setEdubytesData] = useState([]);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 8;

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const getEdubytes = async () => {
    setButtonSpinner(true);
    const { success, data } = await getApiWithAuth(`${API_URL.SCHOOL_EDUBYTES}`);
    if (success) {
      setEdubytesData(data);
      setCurrentPage(1);
      setButtonSpinner(false);
    } else {
      setButtonSpinner(false);
    }
  };

  useEffect(() => {
    getEdubytes();
  }, []);

  const totalPages = Math.ceil(edubytesData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = edubytesData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div className="container-fluid p-2 p-md-3 p-lg-4 overViewMianDivHeight">
        <AuthenticatedNavbar title={t('edubytes')} />
        <Stack data-testid="edubytes-page" direction="column" alignItems="flex-start" spacing={30} style={{ marginTop: 30 }}>
          <Row>
            <Col span={18}>
              <Text size="small">{t('edubytes_description')}</Text>
            </Col>
          </Row>
          {buttonSpinner ? (
            <Stack direction="column" justifyContent="center" style={{ minHeight: '300px' }}>
              <Spin size="large" />
            </Stack>
          ) : edubytesData?.length === 0 ? (
            <Stack data-testid="no-edubytes" direction="column" justifyContent="center" style={{ minHeight: '300px' }}>
              <Empty description={false} />
            </Stack>
          ) : (
            <Stack direction="column" alignItems='flex-start'>
              <Row style={{ width: '100%'}} gutter={[16, 16]}>
                {currentItems?.map((item, index) => {
                  return (
                    <Col span={6} lg={6} md={8} sm={12} xs={24} key={`${item.id}-${index}`}>
                      <EdubyteCard edubyteItem={item} />
                    </Col>
                  );
                })}
              </Row>
              <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </Stack>
          )}
        </Stack>
      </div>
    </>
  );
};

export default Edubytes;
