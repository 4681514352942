import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Document, Font, Image, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
import logo from '@assets/images/new_logo.png';
import evilLord from '@assets/images/evil_lord_hacker.png';
import username from '@assets/images/icon_username.png';
import password from '@assets/images/icon_password.png';
import nameTag from '@assets/images/icon_nameTag.png';

import catamaranRegular from '@assets/fonts/Catamaran-Regular.ttf';
import catamaranBold from '@assets/fonts/Catamaran-Bold.ttf';
import lexendRegular from '@assets/fonts/Lexend-Regular.ttf';
import lexendBold from '@assets/fonts/Lexend-Bold.ttf';
import { API_URL, getApiWithAuth, REACT_APP_CYBER_ACADEMY_URL } from '@utils';
import { message, Modal, Spin } from 'antd';
import { Stack } from '@teacher-app/design-system';
import { useTranslation } from 'react-i18next';

Font.register({
  family: 'Catamaran',
  fonts: [
    {
      src: catamaranRegular,
      fontWeight: 'normal'
    },
    {
      src: catamaranBold,
      fontWeight: 'bold'
    }
  ]
});

Font.register({
  family: 'Lexend',
  fonts: [
    {
      src: lexendRegular,
      fontWeight: 'normal'
    },
    {
      src: lexendBold,
      fontWeight: 'bold'
    }
  ]
});

export const styles = StyleSheet.create({
  page: {
    padding: 50
  },
  logo: {
    height: 52,
    width: 100,
    marginBottom: 10
  },
  headerHeadingContainer: {
    flexDirection: 'column',
    height: 125,
    marginBottom: 15
  },
  headerHeading: {
    fontSize: '36px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '800',
    color: '#222'
  },
  headerText: {
    fontSize: '16px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '100%',
    color: '#3B3D3C',
    marginBottom: 5
  },
  bodyRow: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    marginTop: 20,
    width: '100%',
    justifyContent: 'space-between'
  },
  bodyLeft: {
    width: '68%',
    justifyContent: 'space-between'
  },
  bodyRight: {
    width: '31%',
    justifyContent: 'space-between'
  },
  bodyHeading: {
    fontSize: '24px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '800',
    width: '70%',
    color: '#674EFF',
    marginBottom: 5,
    marginTop: 20
  },
  bodyText: {
    fontSize: '15px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '100%',
    color: '#3B3D3C',
    marginBottom: 5
  },
  stepRow: {
    flexDirection: 'row',
    width: '80%',
    marginBottom: 5,
    marginTop: 5
  },
  stepNumber: {
    fontSize: '24px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '800',
    color: '#674EFF',
    marginRight: 10
  },
  stepText: {
    fontSize: '15px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '100%',
    color: '#3B3D3C'
  },
  detailRow: {
    flexDirection: 'row',
    marginBottom: 5,
    marginTop: 5,
    alignItems: 'center'
  },
  detailImage: {
    height: 40,
    width: 40,
    marginRight: 15
  },
  detailText: {
    fontSize: '15px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '400',
    color: '#3B3D3C'
  },
  detailHeading: {
    fontSize: '24px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '800',
    color: '#674EFF',
    marginTop: 30,
    marginBottom: 5
  },
  detailTextBold: {
    fontSize: '15px',
    fontFamily: 'Catamaran',
    fontStyle: 'normal',
    fontWeight: '800',
    color: '#3B3D3C',
    marginRight: 5
  },
  footer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    columnGap: 5,
    fontSize: 12,
    width: '100%',
    bottom: 50,
    paddingLeft: 50,
    paddingRight: 50,
    left: 0
  },
  footerHelp: {
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    color: '#3B3D3C'
  },
  footerEmail: {
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '800',
    lineHeight: '150%',
    color: '#674EFF'
  },
  footerPage: {
    position: 'absolute',
    fontSize: '14px',
    fontFamily: 'Lexend',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    bottom: 50,
    right: 50,
    color: '#8E8E8E'
  }
});

const PasswordPdfDoc = ({ loginDetails }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'login_instruction_pdf' });

  return (
    <Document title={'Login Instructions'}>
      {loginDetails.map((loginDetail, index) => (
        <Page key={index} size="A4" style={styles.page}>
          <View>
            <Image style={styles.logo} src={logo} />
            <View style={styles.headerHeadingContainer}>
              <Text style={styles.headerHeading}>{t('heading_part_1')}</Text>
              <Text style={styles.headerHeading}>{t('heading_part_2')}</Text>
              <Text style={styles.headerHeading}>{t('heading_part_3')}</Text>
            </View>

            <Text style={styles.headerText}>{t('sub_heading')}</Text>
          </View>
          <View style={styles.bodyRow}>
            <View style={styles.bodyLeft}>
              <View>
                <Text style={styles.bodyHeading}>{t('list_heading')}</Text>
                <View style={styles.stepRow}>
                  <Text style={styles.stepNumber}>1</Text>
                  <Text style={styles.stepText}>{t('list_item_1', {url: REACT_APP_CYBER_ACADEMY_URL})}</Text>
                </View>
                <View style={styles.stepRow}>
                  <Text style={styles.stepNumber}>2</Text>
                  <Text style={styles.stepText}>{t('list_item_2')}</Text>
                </View>
              </View>
              <View>
                <Text style={styles.detailHeading}>{t('student_detail_heading')}</Text>
                <View style={styles.detailRow}>
                  <Image style={styles.detailImage} src={nameTag} />
                  <Text style={styles.detailTextBold}>{t('student_name')}</Text>
                  <Text style={styles.detailText}>
                    {loginDetail?.first_name} {loginDetail?.last_name}
                  </Text>
                </View>
                <View style={styles.detailRow}>
                  <Image style={styles.detailImage} src={username} />
                  <Text style={styles.detailTextBold}>{t('student_username')}</Text>
                  <Text style={styles.detailText}>{loginDetail?.username}</Text>
                </View>
                <View style={styles.detailRow}>
                  <Image style={styles.detailImage} src={password} />
                  <Text style={styles.detailTextBold}>{t('student_password')}</Text>
                  <Text style={styles.detailText}>{loginDetail?.password}</Text>
                </View>
              </View>
            </View>
            <View style={styles.bodyRight}>
              <Image src={evilLord} />
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

PasswordPdfDoc.propTypes = {
  loginDetails: PropTypes.array.isRequired
};

export const LoginInstructionsPdfs = ({ isOpen, onClose, currentClass }) => {
  const [loading, setLoading] = useState(true);
  const [loginDetails, setLoginDetails] = useState(null);

  useEffect(async () => {
    setLoading(true);
    const { success, data } = await getApiWithAuth(
      `${API_URL.EDIT_CLASS_BY_ID}${currentClass.id}/students/login-details`
    );

    if (success) {
      setLoginDetails(data);
      setLoading(false);
    } else {
      message.error(data.message);
      setLoading(false);
    }
  }, []);

  return (
    <Modal
      width="80vw"
      closable={false}
      centered
      footer={null}
      onCancel={onClose}
      open={isOpen}
      style={{ padding: 0 }}
      bodyStyle={{ padding: 0 }}>
      {loading && (
        <Stack style={{ minHeight: 160 }} justifyContent="center">
          <Spin size="large" />
        </Stack>
      )}
      {!loading && loginDetails && (
        <Stack direction="column" alignItems="flex-start" spacing={12}>
          <PDFViewer style={{ width: '100%', height: '90vh' }}>
            <PasswordPdfDoc loginDetails={loginDetails} />
          </PDFViewer>
        </Stack>
      )}
    </Modal>
  );
};

LoginInstructionsPdfs.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentClass: PropTypes.object.isRequired
};

export const LoginInstructionsPdf = ({ loginDetail }) => {
  return (
    <PDFDownloadLink
      document={<PasswordPdfDoc loginDetails={[loginDetail]} />}
      fileName={`Login Instructions - ${loginDetail?.username}.pdf`}>
      {({ loading }) => (loading ? 'Loading document...' : 'Download')}
    </PDFDownloadLink>
  );
};

LoginInstructionsPdf.propTypes = {
  loginDetail: PropTypes.object.isRequired
};
