import dayjs from 'dayjs';

export const getAssignmentStatus = (startDate, endDate, t, assignmentStatus) => {
  let status = t('completed');
  if(assignmentStatus && assignmentStatus === 'Canceled') {
    return {
      status: t('deleted'),
      type: 'invalid'
    };
  }
  let type = 'invalid';
  if (startDate > dayjs().format('YYYY-MM-DD')) {
    status = t('starting_soon');
    type = 'warning';
  } else if (startDate <= dayjs().format('YYYY-MM-DD') && endDate > dayjs().format('YYYY-MM-DD')) {
    status = t('active');
    type = 'success';
  } else if (endDate === dayjs().format('YYYY-MM-DD')) {
    status = t('due_today');
    type = 'danger';
  }
  return { status, type };
};