import React, { useEffect, useState } from 'react';
import { Image, Input, Spin, Tooltip } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { Edit, Key, Search as SearchIcon } from 'react-feather';

import { Button, Card, Colors, Stack, Table, Text, Title, Form } from '@parent-app/design-system';

import notFoundImage from '@assets/images/icon_makeAccountWebsite.svg';
import useSWR from 'swr';
import { API_URL, fetcher } from '@utils';
import ChildLoginDetailModal from '@parent-app/components/ChildLoginDetailModal';
import AddChildModal from '@parent-app/components/AddChildModal';
import AddChildSuccessModal from '@parent-app/components/AddChildModal/AddChildSuccessModal';

const columns = (t, setSelectedLoginDetails) => [
  {
    title: t('child_name'),
    dataIndex: 'child_name',
    key: 'child_name',
    showSorterTooltip: false,
    sorter: (a, b) => a?.child_name?.localeCompare(b?.child_name)
  },
  {
    title: t('grade'),
    dataIndex: 'child_grade',
    key: 'student_id'
  },
  {
    title: t('email'),
    dataIndex: 'child_email',
    key: 'child_email'
  },
  {
    dataIndex: 'action',
    key: 'action',
    render: (_, child) => (
      <Stack key={child.child_id} spacing={20} justifyContent="flex-end" style={{ flexWrap: 'wrap' }}>
        <Tooltip color="purple" title={t('child_login_details')}>
          <Key
            style={{ cursor: 'pointer', color: Colors.VIOLET, flex: '1 1 20px' }}
            size={20}
            onClick={() => setSelectedLoginDetails(child)}
          />
        </Tooltip>
        <Tooltip color="purple" title={t('edit_child')}>
          <Edit
            style={{ cursor: 'pointer', color: Colors.VIOLET, flex: '1 1 20px' }}
            size={20}
          />
        </Tooltip>
      </Stack>
    )
  }
];

const ChildList = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'parents' });
  const { data, isLoading, mutate } = useSWR(`${API_URL.PARENT_CHILDREN}`, fetcher);
  const children = data?.data || [];
  const [searchKey, setSearchKey] = useState('');
  const [filteredList, setFilteredList] = useState();

  const [showAddChildModal, setShowAddChildModal] = useState(false);
  const [selectedLoginDetails, setSelectedLoginDetails] = useState(null);
  const [addChildSuccessModal, setAddChildSuccessModal] = useState(null);

  const handleSearch = e => {
    const { value } = e.target;
    setSearchKey(value);
  };

  const childrenData =
    children &&
    children.map(child => {
      return {
        id: child.student_id,
        child_id: child.student_id,
        child_name: `${child.first_name} ${child.last_name}.`,
        child_grade: child.student_grade,
        child_email: child.student_email || '-',
        ...child
      };
    });

  useEffect(async () => {
    if (childrenData) {
      if (searchKey !== '') {
        setFilteredList(childrenData?.filter(child => child?.child_name?.toLowerCase().includes(searchKey?.toLowerCase())));
      } else {
        setFilteredList(childrenData);
      }
    }
  }, [searchKey, childrenData.length]);

  const handleAddChildFinish = async(loginDetails) => {
    await mutate();
    setShowAddChildModal(false);
    setAddChildSuccessModal(loginDetails);
  };

  return (
    <>
      <Card width='100%'>
        {isLoading ? (
          <Stack direction="column" alignItems="flex-start">
            <Stack justifyContent='space-between' style={{ minHeight: 68 }}>
              <Title level={3}>{t('accounts')}</Title>
              <Button size='small' onClick={() => setShowAddChildModal(true)}>
                {t('add_child')}
              </Button>
            </Stack>
            <Stack style={{ paddingTop: 40, minHeight: 160 }} justifyContent="center">
              <Spin size="large" />
            </Stack>
          </Stack>
        ) : children?.length === 0 ? (
          <Stack direction="column" alignItems="flex-start">
            <Stack justifyContent='space-between' style={{ minHeight: 68 }}>
              <Title level={3}>{t('accounts')}</Title>
              <Button size='small' onClick={() => setShowAddChildModal(true)}>
                {t('add_child')}
              </Button>
            </Stack>
            <Stack
              style={{ paddingTop: 40, paddingBottom: 20, minHeight: 160 }}
              direction="column"
              justifyContent="center">
              <Image src={notFoundImage} preview={false} />
              <Title style={{ textAlign: 'center' }} level={3}>
                {t('empty_here')}
              </Title>
              <Text style={{ textAlign: 'center' }} size="small">
                {t('empty_here_detail')}
              </Text>
            </Stack>
          </Stack>
        ) : (
          children && (
            <Stack direction="column" alignItems="flex-start" spacing={33}>
              <Stack justifyContent='space-between' style={{ minHeight: 68 }}>
                <Stack direction="column" alignItems="flex-start">
                  <Title level={3}>{t('accounts')}</Title>
                  <Trans>
                    <Text size="small">
                      {t('accounts_subtext', { noOfChildren: children.length, text: children.length === 1 ? '' : 's' })}
                    </Text>
                  </Trans>
                </Stack>
                <Button size='small' onClick={() => setShowAddChildModal(true)}>
                  {t('add_child')}
                </Button>
              </Stack>
              <Stack style={{ paddingBottom: 20 }} direction="column" alignItems="flex-start" spacing={30}>
                <Form>
                  <Form.Item>
                    <Input
                      prefix={<SearchIcon color={Colors.WHITE_200} />}
                      style={{ borderRadius: 100, paddingRight: 20 }}
                      placeholder={t('search_placeholder')}
                      onChange={handleSearch}
                    />
                  </Form.Item>
                </Form>
                <Table
                  columns={columns(t, setSelectedLoginDetails)}
                  dataSource={filteredList}
                  pagination={{ position: ['bottomCenter'], total: children.length, defaultPageSize: 5, hideOnSinglePage: true }}
                />
              </Stack>
            </Stack>
          )
        )}
      </Card>
      <AddChildModal
        isOpen={showAddChildModal}
        onClose={() => setShowAddChildModal(false)}
        handleOnFinish={handleAddChildFinish}
      />
      <ChildLoginDetailModal
        isOpen={!!selectedLoginDetails}
        onClose={() => setSelectedLoginDetails(null)}
        selectedLoginDetails={selectedLoginDetails}
      />
      <AddChildSuccessModal
        isOpen={!!addChildSuccessModal}
        selectedLoginDetails={addChildSuccessModal}
        onClose={() => setAddChildSuccessModal(null)}
      />
    </>
  );
};

export default ChildList;