import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Stack, Text, Title } from '@teacher-app/design-system';
import DeleteIcon from '@assets/images/Frame.svg';
import { Image, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { deleteApiWithAuth } from '@utils';

const DeleteAssignmentModal = ({ isOpen, onClose, refetchAssignments, assignmentId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });

  const deleteAssignment = async () => {
    const { success, data } = await deleteApiWithAuth(
      `school/assignments/${assignmentId}`,
      {
        pk: assignmentId,
      }
    );
    if (success) {
      message.success(t('delete_assignment_success'));
      refetchAssignments();
      onClose();
    } else {
      message.error(data.message);
      onClose();
    }
  };


  return (
    <Modal data-testid="delete-assignment-modal" centered={false} onCancel={onClose} open={isOpen}>
      <Stack direction="column" spacing={50}>
        <Stack direction="column" spacing={22}>
          <Image src={DeleteIcon} preview={false} />
          <Stack direction="column" alignItems="center" spacing={20}>
            <Title style={{ textAlign: 'center' }} level={3}>{t('delete_assignment_modal_title')}</Title>
            <Text className="text-center">{t('delete_assignment_modal_description')}</Text>
          </Stack>
        </Stack>
        <Stack justifyContent='center'>
          <Button data-testid="delete-assignment-confirm-button" onClick={deleteAssignment}>{t('yes_delete_button')}</Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default DeleteAssignmentModal;

DeleteAssignmentModal.propTypes = {
  assignmentId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetchAssignments: PropTypes.func.isRequired,
};
