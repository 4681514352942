import React, { useState } from 'react';
import { List, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button, Card, Link, Title, Text, Stack, Pagination, Colors } from '@parent-app/design-system';

import { API_URL, fetcher } from '@utils';
import useSWR from 'swr';
import AddChildModal from '@parent-app/components/AddChildModal';
import ChildLoginDetailModal from '@parent-app/components/ChildLoginDetailModal';
import AddChildSuccessModal from '@parent-app/components/AddChildModal/AddChildSuccessModal';

export const LoginDetails = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'parents' });
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddChildModal, setShowAddChildModal] = useState(false);
  const { data, isLoading, mutate } = useSWR(`${API_URL.PARENT_CHILDREN_PAGE}?page=${currentPage}`, fetcher);
  const { count, results } = data?.data || {};
  const [selectedLoginDetails, setSelectedLoginDetails] = useState(null);
  const [addChildSuccessModal, setAddChildSuccessModal] = useState(null);

  const handleAddChildFinish = async (loginDetails) => {
    await mutate();
    setShowAddChildModal(false);
    setAddChildSuccessModal(loginDetails);
  };

  return (
    <>
    <Card width="100%">
      {isLoading ? (
        <Stack style={{ minHeight: 160 }} justifyContent="center">
          <Spin size="large" />
        </Stack>
      ) : !results?.length ? (
        <Stack direction="column" alignItems="flex-start" spacing={20}>
          <Title level={3}>{t('login_details')}</Title>
          <Text size="small">{t('no_child')}</Text>
          <Button type="secondary" block>
            {t('add_child')}
          </Button>
        </Stack>
      ) : (
        <Stack direction="column" alignItems="flex-start" spacing={20}>
          <Title level={3}>{t('login_details')}</Title>
          <Text size="small">{t('click_child_description')}</Text>
          <List
            style={{ width: '100%' }}
            itemLayout="horizontal"
            dataSource={results}
            renderItem={item => (
              <List.Item style={{ padding: '20px 0', borderColor: Colors.WHITE_200 }}>
                <Link size="medium" hideIcon onClick={() => setSelectedLoginDetails(item)}>
                  {`${item.first_name} ${item.last_name}`}
                </Link>
              </List.Item>
            )}
          />
          <Stack justifyContent="center">
            <Pagination
              defaultPageSize={5}
              current={currentPage}
              total={count}
              onChange={page => setCurrentPage(page)}
              hideOnSinglePage
            />
          </Stack>
          <Button type="secondary" block onClick={() => setShowAddChildModal(true)}>
            {t('add_child')}
          </Button>
        </Stack>
      )}
    </Card>
    <AddChildModal
      isOpen={showAddChildModal}
      onClose={() => setShowAddChildModal(false)}
      handleOnFinish={handleAddChildFinish}
    />
    <ChildLoginDetailModal
      isOpen={!!selectedLoginDetails}
      onClose={() => setSelectedLoginDetails(null)}
      selectedLoginDetails={selectedLoginDetails}
    />
    <AddChildSuccessModal
      isOpen={!!addChildSuccessModal}
      selectedLoginDetails={addChildSuccessModal}
      onClose={() => setAddChildSuccessModal(null)}
    />
    </>
  );
};
export default LoginDetails;
