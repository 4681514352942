import React from 'react';
import { Card, Stack, Title, Text, Link } from '@parent-app/design-system';
import { Trans, useTranslation } from 'react-i18next';

const DeleteAccount = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'parents' });
  return (
    <Card>
      <Stack direction="column" alignItems="flex-start">
        <Title level={3}>{t('delete_account')}</Title>
        <Text size="small">
          <Trans i18nKey="parents.account_deletion_warning" ns="translation">
            Deleting your account is a permanent action that will cancel all your current subscriptions (which are non
            refundable). If you’re just looking to change to a free account, you can change your subscription. Email us at <Link href="mailto:legal@cyberlegends.com" style={{ display: 'inline-block' }} hideIcon>legal@cyberlegends.com</Link> if you’d like to
            delete your account.
          </Trans>
        </Text>
      </Stack>
    </Card>
  );
};

export default DeleteAccount;