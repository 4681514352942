import { useEffect } from 'react';
import { REACT_APP_CYBER_ACADEMY_URL } from '@utils';

const StudentSignIn = () => {
  useEffect(() => {
    window.location.href = REACT_APP_CYBER_ACADEMY_URL;
  }, []);

  return null;
};
export default StudentSignIn;
