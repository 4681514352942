import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Link, Title, Text, Stack } from '@teacher-app/design-system';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import SupportVideoPlayerModal from '@teacher-app/components/SupportVideoPlayerModal';

const VIDEOS = {
  add_students : {
    videoUrl: 'https://youtu.be/LFsKK3jLZP8',
    titleKey: 'add_students'
  },
  create_assignments : {
    videoUrl: 'https://youtu.be/QvRhg-w39nc',
    titleKey: 'create_assignments'
  },
  overview : {
    videoUrl: 'https://youtu.be/zT9nPYq1XT4',
    titleKey: 'overview'
  },
  resources : {
    videoUrl: 'https://youtu.be/SBAS8yfdZRE',
    titleKey: 'resources'
  },
};

export const SupportHelpfulLinks = ({ hideGlossary }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'support' });
  const navigate = useNavigate();
  const [videoDetail, setVideoDetail] = useState(null);

  return (
    <>
    <Card width="100%" data-testid="support-helpful-links">
        <Stack direction="column" alignItems="flex-start" spacing={30}>
          <Title level={3}>{t('links')}</Title>
            <Stack direction="column" alignItems="flex-start">
              <Link ellipsis onClick={() => setVideoDetail(VIDEOS['overview'])}>
                {t('overview')}
              </Link>
              <Text size="small">{t('overview_video')}</Text>
            </Stack>
            <Stack direction="column" alignItems="flex-start">
              <Link ellipsis onClick={() => setVideoDetail(VIDEOS['add_students'])}>
                {t('add_students')}
              </Link>
              <Text size="small">{t('add_students_video')}</Text>
            </Stack>
            <Stack direction="column" alignItems="flex-start">
              <Link ellipsis onClick={() => setVideoDetail(VIDEOS['resources'])}>
                {t('resources')}
              </Link>
              <Text size="small">{t('resources_video')}</Text>
            </Stack>
          <Stack direction="column" alignItems="flex-start">
            <Link ellipsis onClick={() => setVideoDetail(VIDEOS['create_assignments'])}>
              {t('create_assignments')}
            </Link>
            <Text size="small">{t('create_assignments_video')}</Text>
          </Stack>
          {!hideGlossary && <Stack direction="column" alignItems="flex-start">
            <Link ellipsis onClick={() => navigate('/support/glossary-terms')}>
              {t('glossary')}
            </Link>
            <Text size="small">{t('glossary_resource')}</Text>
          </Stack>}
        </Stack>
    </Card>
      <SupportVideoPlayerModal
        isOpen={!!videoDetail}
        onClose={() => setVideoDetail(null)}
        video={{ url: videoDetail?.videoUrl, name: t(videoDetail?.titleKey) }}
      />
    </>
  );
};
export default SupportHelpfulLinks;

SupportHelpfulLinks.defaultProps = {
  hideGlossary: false
};

SupportHelpfulLinks.propTypes = {
  hideGlossary: PropTypes.bool
};