import './parentSignIn.css';
import { useEffect } from 'react';
import { REACT_APP_PARENT_HOME_URL } from '@utils';

const ParentSignIn = () => {
  useEffect(() => {
    window.location.href = REACT_APP_PARENT_HOME_URL;
  }, []);

  return null;
};
export default ParentSignIn;
